import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import Antd from 'ant-design-vue';
import 'vant/lib/index.css';
import './global.less'

const app = createApp(App);
app.use(store);
app.use(router);
app.use(Antd);
app.use(Vant);
app.mount('#app');

// 动态设置标题
// router.afterEach((to, from) => {
//     document.title = '火花潮玩 - ' + to.meta.title;
// })
