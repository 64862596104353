import { createRouter, createWebHistory } from 'vue-router'
import { addRouterMap } from '@/config/router.config'
import { userAuthority } from '@/config/userAuthority'
import { message } from 'ant-design-vue';
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: addRouterMap
})
// 路由拦截
router.beforeEach((to, from, next) => {
  if (to.matched.some(res => res.meta.requireAuth)) { // 验证是否需要登陆
  const token = localStorage.getItem('Access-Token');
  if (token) { // 查询本地存储信息是否已经登陆
    // 权限判断
    const userLevel = localStorage.getItem('user-limit');
    if (to.meta.roles.indexOf(userAuthority[userLevel]) !== -1) {
      next();
    } else {
     
      message.error('您的权限有误');
      next({
        path: '/login', // 未登录则跳转至login页面
      });
    }
  } else {
      next({
        path: '/login', // 未登录则跳转至login页面
      });
  }
  } else {
    next();
  }
})
export default router
