<template>
  <a-config-provider :locale="locale">
    <div class="page-main-view">
      <router-view/>
    </div>
  </a-config-provider>
</template>

<script setup>
  import { ref } from 'vue';
  import zhCN from 'ant-design-vue/es/locale/zh_CN';
  import dayjs from 'dayjs';
  import { useStore } from 'vuex';
  import 'dayjs/locale/zh-cn';
  const store = useStore();
  dayjs.locale('zh-cn');
  const locale = ref(zhCN)
  if(navigator.userAgent.match(/(iPhone|iPod|Android|ios)/i)){
    store.dispatch('setAdminPlatform', 'phone');
    console.log('手机');
  }else {
    store.dispatch('setAdminPlatform', 'web');
    console.log('电脑');
  }
</script>

<style lang="less">
  body {
    background-color: #f1f1f1;
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // width: 100vw;
    height: 100vh;
    position: relative;
  }
  .page-main-view {
    // width: 100vw;
    height: 100vh;
    position: relative;
  }
</style>
