<template>
  <a-layout style="min-height: 100vh">
    <a-layout-sider v-model:collapsed="collapsed" :style="{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0, top: 0, bottom: 0, zIndex: 1 }">
      <div class="logo">
        <div class="top-logo">
          <a-avatar class="logo-image" src="https://vipkh.oss-cn-beijing.aliyuncs.com/MHImage/logo.png"></a-avatar>
          <div class="logo-name">潮游科技</div>
        </div>
      </div>
      <a-menu theme="dark" v-model:selectedKeys="selectedKeys" mode="inline">
        <span v-for="(item, index) in menus" :key="item.name">
          <span v-if="item.meta.isChild">
            <a-sub-menu :key="index">
              <template #title>
                <span>
                  <MenuOutlined v-if="index!==0"/>
                  <span :style="{'fontSize': index? '15px':'18px'}">{{ item.meta.title }}</span>
                </span>
              </template>
              <a-menu-item  v-for="(newItem, newIndex) in item.children" :key="newItem.name" @click="onMenuEvent(newItem)">
                <span>{{ newItem.meta.title }}</span>
              </a-menu-item>
            </a-sub-menu>
          </span>
          <span v-else>
            <a-menu-item :key="index" @click="onMenuEvent(item)">
              <MenuOutlined v-if="index!==0"/>
              <span :style="{'fontSize': index? '15px':'18px'}">{{ item.meta.title }}</span>
            </a-menu-item>
          </span>
        </span>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header style="background: #fff; padding: 0;">
        <a-dropdown placement="bottomRight">
          <a class="ant-dropdown-link user-head" @click.prevent>
            <a-avatar :size="38" :src="userHead" />
            <a style="margin-left: 10px;">{{ userName }}</a>
          </a>
          <template #overlay>
            <a-menu>
              <a-menu-item @click="onLogonOutEvent">
                <span style="margin-left: 4px;">退出登录</span>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </a-layout-header>
      <a-layout-content>
        <div class="content-view">
          <router-view/>
        </div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
import {
  LoginOutlined,
  LogoutOutlined,
  MenuOutlined,
  PieChartOutlined,
  DesktopOutlined,
  UserOutlined,
  TeamOutlined,
  FileOutlined,
} from '@ant-design/icons-vue';
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import { addRouterMap } from '@/config/router.config'
import { userAuthority } from '@/config/userAuthority'
import { useRouter } from 'vue-router';
import { message } from 'ant-design-vue';
export default defineComponent({
  name: 'homeView',
  components: {
    PieChartOutlined,
    DesktopOutlined,
    UserOutlined,
    TeamOutlined,
    FileOutlined,
    MenuOutlined,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const selectedKeys = ref([0]);
    const collapsed = ref(false);
    // 获取菜单
    const menus = ref([]);
    const userLevel = localStorage.getItem('user-limit');
    const newAddRouterMap = JSON.parse(JSON.stringify(addRouterMap))
    const routes = newAddRouterMap.find(item => item.path === '/')
    const routesChildren = routes.children.find(item => item.path === '/warehouseManage')
    const newChildren = routesChildren.children.filter(item => item.roles.indexOf(userAuthority[userLevel]) !== -1)
    routes.children = routes.children.filter(item => item.roles.indexOf(userAuthority[userLevel]) !== -1)
    routes.children.forEach(item => {
      if (item.name === 'warehouseManage') {
        item.children = newChildren
      }
    })
    menus.value = (routes && routes.children) || []
    
    const userName = ref('');
    const userHead = ref('');
    store.dispatch('getCityData');
    store.dispatch('GetInfo').then(() => {
      userName.value = store.getters["getUserName"];
      userHead.value = store.getters["getUserHead"];
    })
    const onMenuEvent = (parent) => {
      router.push(parent.path)
    }
    // 退出登录
    const onLogonOutEvent = () => {
      store.dispatch('Logout')
      .finally(() => {
        message.info('退出成功');
        setTimeout(() => {
          router.push('/login')
        }, 1500);
      })
    }
    return {
      menus,
      selectedKeys,
      collapsed,
      userName,
      userHead,
      onMenuEvent,
      onLogonOutEvent,
    }
  },
});
</script>
<style lang="less">
#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}
[data-theme='dark'] .site-layout .site-layout-background {
  background: #141414;
}
.top-logo {
  width: 100%;
  height: 60px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .logo-image {
    width: 40px;
    height: 40px;
    position: relative;
  }
  .logo-name {
    font-size: 22px;
    color: #fff;
    font-weight: bolder;
    margin-left: 10px;
  }
}
.content-view {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
  position: relative;
  margin: 20px;
  margin-left: 0px;
  padding-left: 220px;
  border: 1px solid #f0f0f0;
}
.user-head {
  position: absolute;
  right: 40px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
