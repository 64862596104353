<template>

  <div class="content-main-view" :style="{'width': strWindowSize[0], 'height': strWindowSize[1]}">
    <!-- <van-nav-bar
      title="库存列表"
      rightText="退出登录"
      fixed
      @click-right="onLogonOutEvent"
    /> -->
    <div class="router-view-main">
      <router-view/>
    </div>
  </div>
</template>
<script>
import {
  LoginOutlined,
  LogoutOutlined,
  MenuOutlined,
  PieChartOutlined,
  DesktopOutlined,
  UserOutlined,
  TeamOutlined,
  FileOutlined,
} from '@ant-design/icons-vue';
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import { addRouterMap } from '@/config/router.config'
import { userAuthority } from '@/config/userAuthority'
import { useRouter } from 'vue-router';
import { message } from 'ant-design-vue';
export default  ({
  name: 'homeView',
  components: {
    PieChartOutlined,
    DesktopOutlined,
    UserOutlined,
    TeamOutlined,
    FileOutlined,
    MenuOutlined,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const selectedKeys = ref([0]);
    const collapsed = ref(false);
    const strWindowSize = ref(store.getters["getWindowSize"]);
    // 获取菜单
    const menus = ref([]);
    const userLevel = localStorage.getItem('user-limit');
    const newAddRouterMap = JSON.parse(JSON.stringify(addRouterMap))
    const routes = newAddRouterMap.find(item => item.path === '/')
    const routesChildren = routes.children.find(item => item.path === '/warehouseManage')
    const newChildren = routesChildren.children.filter(item => item.roles.indexOf(userAuthority[userLevel]) !== -1)
    routes.children = routes.children.filter(item => item.roles.indexOf(userAuthority[userLevel]) !== -1)
    routes.children.forEach(item => {
      if (item.name === 'warehouseManage') {
        item.children = newChildren
      }
    })
    menus.value = (routes && routes.children) || []
    
    const userName = ref('');
    const userHead = ref('');
    store.dispatch('getCityData');
    store.dispatch('GetInfo').then(() => {
      userName.value = store.getters["getUserName"];
      userHead.value = store.getters["getUserHead"];
    })
    const onMenuEvent = (parent) => {
      router.push(parent.path)
    }
    // 退出登录
    const onLogonOutEvent = () => {
      store.dispatch('Logout')
      .finally(() => {
        message.info('退出成功');
        setTimeout(() => {
          router.push('/login')
        }, 1500);
      })
    }
    return {
      menus,
      selectedKeys,
      collapsed,
      userName,
      userHead,
      strWindowSize,
      onMenuEvent,
      onLogonOutEvent,
    }
  },
});
</script>
<style lang="less">
.content-main-view {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  .router-view-main {
    flex: 1;
    position: relative;
  }
}
</style>
